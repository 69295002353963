import classNames from 'classnames';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import Image from '@/components/common/image/Image';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {Media, MediaContextProvider} from '@/media';

import IconCards from './assets/cards_2_outline_28.svg';
import IconComputer from './assets/computer_smartphone_outline_28.svg';
import VkTechImg from './assets/vk-tech-img.webp';
import IconBag from './assets/work_outline_28.svg';
import styles from './VkTechPromo.module.css';

export function VkTechPromo() {
	return (
		<div className={styles.wrapper}>
			<Section
				isFullWidth
				style={SECTION_STYLES.WITH_BREAKPOINTS}
				direction="column"
				className={styles.root}
			>
				<div className={styles.grid}>
					<div className={classNames(styles.card, styles.bigCard)}>
						<Typography variant={VARIANTS.h2} className={styles.title}>
							25 лет
							<br />
							технологической
							<br />
							экспертизы
						</Typography>
						<div className={styles.imageWrapper}>
							<MediaContextProvider>
								<Media lessThan="desktop" className={styles.wrapper}>
									<Image
										width={225}
										height={155}
										src={VkTechImg}
										className={styles.vkTechImg}
										alt="VK tech"
									/>
								</Media>
								<Media at="desktop" className={styles.wrapper}>
									<Image
										width={247}
										height={170}
										src={VkTechImg}
										className={styles.vkTechImg}
										alt="VK tech"
									/>
								</Media>
								<Media greaterThanOrEqual="desktop_xl" className={styles.wrapper}>
									<Image
										width={332}
										height={229}
										src={VkTechImg}
										className={styles.vkTechImg}
										alt="VK tech"
									/>
								</Media>
							</MediaContextProvider>
						</div>
					</div>
					<div className={classNames(styles.card, styles.cardWithIcon)}>
						<div className={styles.iconWrapper}>
							<IconBag className={styles.icon} />
						</div>
						<Typography variant={VARIANTS.text} className={styles.cardText}>
							Комплексный портфель ИТ-решений
						</Typography>
					</div>
					<div className={classNames(styles.card, styles.cardWithIcon)}>
						<div className={styles.iconWrapper}>
							<IconComputer className={styles.icon} />
						</div>
						<Typography variant={VARIANTS.text} className={styles.cardText}>
							Софтверные продукты для ежедневных задач
						</Typography>
					</div>
					<div className={classNames(styles.card, styles.cardWithIcon)}>
						<div className={styles.iconWrapper}>
							<IconCards className={styles.icon} />
						</div>
						<Typography variant={VARIANTS.text} className={styles.cardText}>
							Более 20 продуктов в реестре отечественного ПО
						</Typography>
					</div>
				</div>
			</Section>
		</div>
	);
}
