import type {NextPage} from 'next';
import Head from 'next/head';

import Footer from '@/components/common/footer/Footer';
import {TemplateForHiddenHeader} from '@/components/common/template-for-hidden-header/TemplateForHiddenHeader';
import {Variants} from '@/components/common/variants/Variants';
import {VkTechPromo} from '@/components/common/vk-tech-promo/VkTechPromo';
import {Accesses} from '@/components/page/vk-teams/accesses/Accesses';
import {AdvancedFunctionality} from '@/components/page/vk-teams/advanced-functionality/AdvancedFunctionality';
import appleTouchIcon from '@/components/page/vk-teams/assets/apple-touch-icon.png';
import favicon from '@/components/page/vk-teams/assets/favicon.ico';
import favicon16 from '@/components/page/vk-teams/assets/favicon-16x16.png';
import favicon32 from '@/components/page/vk-teams/assets/favicon-32x32.png';
import ogImage from '@/components/page/vk-teams/assets/vk-teams-og.png';
import {CommunicationApp} from '@/components/page/vk-teams/communication-app/CommunicationApp';
import {FaqSection} from '@/components/page/vk-teams/faq-section/FaqSection';
import {Promo} from '@/components/page/vk-teams/promo/Promo';
import {Request} from '@/components/page/vk-teams/request/Request';
import {Secure} from '@/components/page/vk-teams/secure/Secure';
import {Services} from '@/components/page/vk-teams/services/Services';
import {withThemeVariables} from '@/hocs/with-theme-variables';
import {useCheckOpenedModal} from '@/hooks/use-check-opened-modal';
import {useInjection} from '@/hooks/use-injection';
import {useOnceWhenEffect} from '@/hooks/use-once-when-effect';
import {useStartFeatures} from '@/hooks/use-start-features';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {getCanonicalUrl} from '@/utilites/get-canonical-url';
import {insertJivoScript} from '@/utilites/script/insert-jivo-script';

import styles from './vk-teams.module.css';
import variablesStyles from './vk-teams-variables.module.css';

interface VKTeamsProps {}

const title = 'Корпоративный мессенджер VK Teams | Суперапп для коммуникаций и совместной работы команд';
const ogTitle = 'Корпоративный мессенджер VK Teams | Суперапп для коммуникаций и совместной работы команд';
const description =
	'Корпоративный суперапп от компании VK. Все инструменты для коммуникаций и совместной работы в режиме одного окна. Приложение объединяет функционал корпоративного мессенджера, аудио- и видеозвонков, управления задачами и работы с документами, позволяет интегрировать корпоративные сервисы c помощью API и чат-ботов.';

const VKTeams: NextPage<VKTeamsProps> = () => {
	const canonicalUrl = getCanonicalUrl('teams');

	const [featureService] = useInjection(IFeatureService);

	useStartFeatures();
	useCheckOpenedModal();
	useOnceWhenEffect(() => featureService.isServiceStarted, insertJivoScript);

	return (
		<div className={styles.root}>
			<Head>
				<title>{title}</title>
				<meta name="description" content={description} />

				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:title" content={ogTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={ogImage.src} />

				<link rel="canonical" href={canonicalUrl} />
				<link rel="icon" href={favicon.src} />
				<link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon.src} />
				<link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
				<link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#000000" />
				<meta name="yandex-verification" content="786cac9e5ffdb048" />
				<meta name="google-site-verification" content="2VSZqo0aCUpXxV19XWK_55iC1O4eLPNlfpAhHvWZHow" />
			</Head>
			<TemplateForHiddenHeader contentStyle={styles.content}>
				<Promo />
				<CommunicationApp />
				<Services />
				<AdvancedFunctionality />
				<Secure />
				<Accesses />
				<Variants />
				<Request />
				<FaqSection />
				<VkTechPromo />
				<Footer />
			</TemplateForHiddenHeader>
		</div>
	);
};

export default withThemeVariables(VKTeams, variablesStyles.cssLocalVariables);
