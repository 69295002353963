import {useCallback} from 'react';

import {
	REQUEST_DIALOG_CONNECTION_BUTTON_TEXT,
	REQUEST_DIALOG_CONNECTION_DESCRIPTION,
	REQUEST_DIALOG_CONNECTION_TITLE,
} from '@/components/common/dialogs/request-dialog/parts';
import {RequestDialog} from '@/components/common/dialogs/request-dialog/RequestDialog';
import {
	SUCCESS_DIALOG_CONNECTION_BUTTON_TEXT,
	SUCCESS_DIALOG_CONNECTION_DESCRIPTION,
	SUCCESS_DIALOG_CONNECTION_TITLE,
} from '@/components/common/dialogs/success-dialog/parts/biz';
import {SuccessDialog} from '@/components/common/dialogs/success-dialog/SuccessDialog';
import {useInjection} from '@/hooks/use-injection';
import {ServiceLandingName} from '@/services/api/interfaces/request';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {
	GA4EventId,
	MetricsCategory,
	TMRReachGoalId,
	UAEventType,
	XrayEventId,
	YAReachGoalId,
} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';

export function useOnPremisesClick() {
	const [lightboxService] = useInjection(ILightboxService);
	const [metricsService] = useInjection(IMetricsService);

	const handleSubmitSuccessConnectionDialog = useCallback(
		(data) => {
			lightboxService.openReadonly(SuccessDialog, {
				title: SUCCESS_DIALOG_CONNECTION_TITLE,
				description: SUCCESS_DIALOG_CONNECTION_DESCRIPTION,
				buttonText: SUCCESS_DIALOG_CONNECTION_BUTTON_TEXT,
			});
			metricsService.sendEvent({
				yaGoals: [YAReachGoalId.RequestFormSubmitTeams],
				tmrGoals: [TMRReachGoalId.OnPremiseMailFormSubmitted],
				ga4Events: [GA4EventId.Submit],
				uaEvents: [
					{
						type: UAEventType.MainEvent,
						payload: {
							category: MetricsCategory.Teams,
							action: 'Submit',
						},
					},
				],
				xrayEvents: [
					{
						name: XrayEventId.clickBtnSubmitAppAmo,
						params: {
							amo_id: data?.amocrm_id,
						},
					},
				],
			});
		},
		[lightboxService, metricsService],
	);

	const handleOpenConnectionDialog = useCallback(() => {
		lightboxService.openReadonly(RequestDialog, {
			landingName: ServiceLandingName.MyTeams,
			title: REQUEST_DIALOG_CONNECTION_TITLE,
			description: REQUEST_DIALOG_CONNECTION_DESCRIPTION,
			buttonText: REQUEST_DIALOG_CONNECTION_BUTTON_TEXT,
			onSubmitSuccess: handleSubmitSuccessConnectionDialog,
		});
		metricsService.sendEvent({
			yaGoals: [YAReachGoalId.RequestFormClickWorkspace],
		});
	}, [lightboxService, metricsService, handleSubmitSuccessConnectionDialog]);

	return {
		handleOpenConnectionDialog,
	};
}
